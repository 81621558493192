.skills-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
}

.skill-category {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.skill-category h4 {
  color: #008080;
  margin-bottom: 10px;
}

.skill-category p {
  color: #666;
  line-height: 1.6;
}
