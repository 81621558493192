

.social-icons-list {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    transform: translateX(-50%);
    display: flex;
    flex-direction: column; /* Display as a vertical list */
    align-items: flex-end; /* Justify content to the right */
    width: auto;
    background-color: #fff;
    padding: 10px; /* Decrease padding */
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.social-icons-list a:hover {
    color: #2196f3; /* Change color on hover */
}

.socialicons {
    margin: 10px; /* Adjust margin */
    color: #009688;
    font-size: 28px; /* Increase font size */
}

.socialicons a:hover {
    color: #2196f3;
}
  body {
      background-color: #f0f0f0;
  }

  .card-panel {
      padding: 20px;
  }
  .profile-image {
      width: 200px;
      height: auto;
  }
  .page-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #333; /* Example background color */
      color: #fff; /* Example text color */
      padding: 20px 0; /* Add some padding for spacing */
  }
  .custom-navbar {
    background-color: teal; /* Red background color */
    color: #fff; /* White text color */
}

.custom-navbar .nav-link {
    color: #fff; /* White text color for nav links */
}

.custom-navbar .navbar-brand {
    color: #fff; /* White text color for navbar brand */
}
.teal-bg {
    background-color: teal;
}

.white-text {
    color: white; /* Set text color to white */
}
.teal-text {
    color: teal;
}
h1 {
    font-size: 1.25rem;
    font-weight: bold;
}

h2 {
    font-size: 1rem;
    font-weight: normal;
}
h3 {
    font-size: 0.9rem; /* Slightly smaller than h2 */
    font-weight: bold; /* Keeping it bold for distinction */
}

h4 {
    font-size: 0.8rem; /* Slightly smaller than h3 */
    font-weight: normal; /* Less emphasis than h3 */
}
.navbar-nav .nav-link {
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  color: white; /* Set the color to white */
}
/* Hide scrollbar thumb */
body::-webkit-scrollbar-thumb {
  display: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: Set scrollbar background color */
}
/* ContactForm.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
} /* Make border transparent on hover */
.carousel-control-prev,
.carousel-control-next {
  width: auto; /* Ensure the controls span the entire height */
  height: 100%; /* Ensure the controls span the entire height */
  background-color: transparent; /* Make the background transparent */
  opacity: 0.5; /* Set initial opacity */
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1; /* Increase opacity on hover */
  background-color: rgba(0, 0, 0, 0.5); /* Change background color on hover */
}
