/* Common styles for sections */
.section,
.contact-section {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Common styles for section titles */
.section-title,
.contact-info h4,
.experience-title {
  text-align: center;
  margin-bottom: 20px;
}

/* Common styles for category and section info */
.category,
.section-info,
.contact-info,
.experience-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Add margin-bottom to create space between each card */
}

/* Specific styles for titles */
.category h4,
.section-info h4 {
  margin-bottom: 10px;
  color: #008080;
}

.contact-info h4,
.experience-title {
  color: #008080;
}

/* Common styles for paragraph */
.category p,
.section-info p,
.contact-info p {
  color: #666;
  font-size: 1em;
  line-height: 1.6;
}

/* Common styles for links */
.category a,
.section-info a,
.contact-info a {
  color: #007bff; /* Example color for links */
  text-decoration: none; /* Removes underline from links */
}

.category a:hover,
.section-info a:hover,
.contact-info a:hover {
  text-decoration: underline; /* Adds underline on hover for better visibility */
}

/* Specific styles for details */
.experience-info {
  color: #666;
  margin-bottom: 10px;
}

.experience-details {
  list-style-type: disc;
  margin-left: 20px;
}

.experience-detail {
  color: #333;
}

/* Specific styles for contact section grid */
.contact-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
